import * as React from 'react';
import { graphql } from 'gatsby';
import Layout, { Content, PageSection } from '../../components/layout';
import { EmailButton } from '../../components/buttons';
import { PageText } from '../../components/typography';
import './jobs.css';
import Seo from '../../components/seo';
import { useCommonTrans } from '../../utils/hooks';

export default function JobTemplate({ data }) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const common = useCommonTrans();

    return (
        <Layout
            headerContent={
                <Content className="pt-8 px-8 pb-2" hasPadding={false}>
                    <h1 className="text-lg font-bold mb-4">
                        <small className="block mb-2">
                            <PageText
                                type="smallCaps"
                                className="text-light-grey"
                            >
                                {frontmatter.type}
                            </PageText>
                        </small>
                        {frontmatter.title}
                    </h1>
                </Content>
            }
            bgImage={{
                file: 'network',
                opacity: 0.2,
            }}
        >
            <Seo title={frontmatter.title} />
            <PageSection>
                <div
                    className="job-container"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </PageSection>
            <PageSection className="text-center" isDark>
                <EmailButton
                    email="jobs@condignum.com"
                    subject={`Bewerbung - ${frontmatter.title}`}
                    jobs={true}
                >
                    {common.contact.contact}
                </EmailButton>
                <PageText className="mt-2">
                    {common.contact.jobMessage}
                </PageText>
            </PageSection>
        </Layout>
    );
}

export const pageQuery = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                slug
                title
                type
            }
        }
    }
`;
